.firebaseui-card-header {
  content: url(/logo-landscape.png);   
  margin: auto;  
  margin-bottom: 20px;
  width: 95%;
}
/* .firebaseui-card-content::before {
  content: 'Sign In To Your Account';
  left: 26%;    
  position: absolute;
} */
.firebaseui-textfield input{
  padding: 5px;
}
.firebaseui-textfield:first-of-type {
  margin-top:30px;
}
.firebaseui-form-actions {
    display: table-header-group !important;
    text-align: center !important;
}
.firebaseui-form-links {
  font-size: 12px;  
  padding-top: 20px;
  text-align: center;
}
.firebaseui-input {
  font-size: 14px !important;
}

.mdl-button--raised.mdl-button--colored {
  background: #335678 !important;
}